
import { generateDefaultName, reportNameGenerator } from "@/utils/reportNameGenerator";
import { StandByReport } from "@socotec.io/socio-vue-components";
import { useService } from "@/setup/connectClient";
import { StandByReportController, ObservationController } from "@socotec.io/socio-grpc-api/connect/services/rapsosps_back/standbyreport_pb";
import {Observation}from "@/models/ExtendedObservation";
import {Code} from "@connectrpc/connect"
const standByReportClient = useService(StandByReportController);
const observationClient = useService(ObservationController);

const state = {
  standByReportsCount: 0,
  observationsCount: 0,
  generatingStandbyReport: false,
};

const getters = {
  getStandByReportsCount: (state) => {
    return state.standByReportsCount;
  },
  getObservationsCount: (state) => {
    return state.observationsCount;
  },
  isReportGenerating: (state) => {
    return state.generatingStandbyReport;
  },
};

const actions = {
  /**
   * Create a standby by report
   * @param [rootGetters]
   * @param reportTemplate
   * @param projectId
   * @param reportGenerationData
   * @returns {Promise<*>}
   */
  async createStandByReport(
    { rootGetters },
    { reportTemplate, project, reportGenerationData }
  ) {
    const reportName = generateDefaultName(project.projectName, reportTemplate.name);

    const request = {
      reportGenerationData: reportGenerationData || {},
      user: rootGetters["user/getCurrentUser"].email,
      usermanagementUuid: rootGetters["user/getCurrentUser"].usermanagementUuid,
      template: reportTemplate.uuid,
      project: project.uuid,
      reportName: reportName,
    };

    const response = await standByReportClient.create(request);
    const standByReport = await StandByReport.insert({
      data: response,
    });

    return standByReport.standByReport[0];
  },

  /**
   * Delete a standby report
   * @param rootGetters
   * @param commit
   * @param standByReportUuid
   * @returns {Promise<*>}
   */
  async deleteStandByReport({ rootGetters, commit }, standByReportUuid) {
    await standByReportClient.destroy({ uuid: standByReportUuid });

    commit(
      "UPDATE_STANDBY_REPORT_COUNT",
      rootGetters["standByReport/getStandByReportsCount"] - 1
    );

    return await StandByReport.delete(standByReportUuid);
  },

  /**
   * Fetch standby reports
   * @param commit
   * @param metadata
   * @returns {Promise<*>}
   */
  async fetchStandByReportList({ commit }, metadata) {
    const response = await standByReportClient.list({}, { headers: metadata });

    commit("UPDATE_STANDBY_REPORT_COUNT", response.count);


    return await StandByReport.insert({
      data: response.results,
    });
  },

  async updateStandByReport({ rootGetters }, { instance }) {
    const reportName = reportNameGenerator(instance.reportName.split(" - ")[0], instance.template, instance.reportGenerationData)
    const request = {
      user: rootGetters["user/getCurrentUser"].email,
      usermanagementUuid: rootGetters["user/getCurrentUser"].usermanagementUuid,
      template: instance.template,
      project: instance.project,
      uuid: instance.uuid,
      reportName: reportName,
      reportGenerationData: instance.reportGenerationData,
    };
    try {
      const response = await standByReportClient.update(request);
      return await StandByReport.update({
        where: instance.uuid,
        data: response,
      });
    } catch (err) {
      console.log(err);
      if(err.code === Code.NotFound) {
        throw new Error(JSON.stringify({
          message: "StandByReport not found!",
          code: "not_found"
        }));
      }
    }
  },

  async retrieveStandByReport(_, standByReportUuid) {
    const response = await standByReportClient.retrieve({ uuid: standByReportUuid });
    const standByReportData = {
      ...response,
      reportGenerationData: response.reportGenerationData,
    };

    const standByReport = await StandByReport.insert({
      data: standByReportData,
    });

    return standByReport.standByReport[0];
  },

  async buildReportGenerationData(context, standByReport) {
    const response = await standByReportClient.buildReportGenerationData(standByReport);

    const { reportGenerationData } = response;
    return { reportGenerationData, response };
  },

  generateStandByReport: async function* generateStandByReport(
    { dispatch, commit },
    { standByReportUuid, metadata = {}, autoFetch = true }
  ) {
    commit("SET_GENERATING_STANDBYREPORT", true);
    try {
      const stream = standByReportClient.generate({ uuid: standByReportUuid }, { headers: metadata });

      console.log("Generating standby report");
      let action = {
        [StandByReport.STATUS_CODE.SUCCESS]: async () => {
          await StandByReport.delete(standByReportUuid);
          commit("SET_GENERATING_STANDBYREPORT", false);
        },
        [StandByReport.STATUS_CODE.GENERATION_FAILED]: () => {
          commit("SET_GENERATING_STANDBYREPORT", false);
        },
        [StandByReport.STATUS_CODE.UPLOAD_FAILED]: () => {
          commit("SET_GENERATING_STANDBYREPORT", false);
        },
        [StandByReport.STATUS_CODE.ERROR]: () => {
          commit("SET_GENERATING_STANDBYREPORT", false);
        },
        [StandByReport.STATUS_CODE.GENERATING]: () => { },
        "NOT_FOUND": () => {
          commit("SET_GENERATING_STANDBYREPORT", false);
        }
      };
      for await (const response of stream) {

        console.log("Response code: ", response.code);
        action[response.code]();

        if (autoFetch && response.code !== "NOT_FOUND") {
          await dispatch(
            "report/fetchReport",
            { reportUuid: response.data.uuid },
            { root: true }
          );
        }
        yield response;
      }

    } catch (err) {
      commit("SET_GENERATING_STANDBYREPORT", false);
      console.error(err);
    }
  },

  async fetchObservations({ commit }, { metadata, operationId }) {
    // Delete front database for this project to reload correctly observations re fetched
    Observation.delete((observation) => {
      return observation.project === operationId;
    });

    try {
      const response = await observationClient.list({}, { headers: metadata });
      commit("UPDATE_OBSERVATIONS_COUNT", response.count);
      const observations = response.results;
      // Insert all objects instance
      return await Observation.insert({
        data: observations.reverse(),
      });
    } catch (err) {
      console.error(err);
    }
  },
};

const mutations = {
  UPDATE_STANDBY_REPORT_COUNT: function (state, newTotal) {
    state.standByReportsCount = newTotal;
  },
  UPDATE_OBSERVATIONS_COUNT: function (state, newTotal) {
    state.observationsCount = newTotal;
  },
  SET_GENERATING_STANDBYREPORT: function (state, newValue) {
    state.generatingStandbyReport = newValue;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};