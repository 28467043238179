import { Document as BaseDocument } from "@socotec.io/socio-vue-components";

export class Document extends BaseDocument {
  static entity = "document";
  static primaryKey = "uuid";

  static fields() {
    return {
      ...super.fields(),
      servicesRelatedName: this.attr([]),
      tags: this.attr([]),
      relatedObjects: this.attr([]),
      file: this.attr({}),
    };
  }
}
