
import { useService } from "@/setup/connectClient";
import { OverridenLastCrController } from "@socotec.io/socio-grpc-api/connect/services/rapsosps_back/projects_pb";
import { listAll } from "@socotec.io/socio-grpc-api/connect/utils";

const client = useService(OverridenLastCrController);

const state = {
  currentOverridenLastCrs: null
};

const getters = {};

const actions = {
  async fetchOverridenLastCr({ commit }, metadata) {
    const response = await listAll(client.list, {}, metadata);
    commit("SET_CURRENT_OVERRIDEN_LAST_CRS", response);
    return response;
  },

  async createOverridenLastCr({commit}, { projectId, company, date}) {
    const dateParts = date.split('/');
    const formatedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    const now = new Date().toISOString()

    const request = {
      project: projectId,
      company: company,
      date: formatedDate,
      createdAt: now,
      updatedAt: now
    };

    const response = await client.create(request);

    commit("ADD_CURRENT_OVERRIDEN_LAST_CRS", response);
    
    return response;
  },

  async updateOverridenLastCr({commit}, { uuid, projectId, company, date}) {
    const dateParts = date.split('/');
    const formatedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    const now = new Date().toISOString()
    
    const request = {
      uuid: uuid,
      project: projectId,
      company: company,
      date: formatedDate,
      createdAt: now,
      updatedAt: now
    };
    
    const response = await client.update(request);
    
    commit("UPDATE_CURRENT_OVERRIDEN_LAST_CRS", response);

    return response;
  },
};

const mutations = {
  SET_CURRENT_OVERRIDEN_LAST_CRS: (state, overridenLastCr) => {
    state.currentOverridenLastCrs = overridenLastCr;
  },

  ADD_CURRENT_OVERRIDEN_LAST_CRS: (state, overridenLastCr) => {
    state.currentOverridenLastCrs.push(overridenLastCr);
  },

  UPDATE_CURRENT_OVERRIDEN_LAST_CRS: (state, overridenLastCr) => {
    const itemIndex = state.currentOverridenLastCrs.findIndex(
      ({ uuid }) => overridenLastCr.uuid === uuid
    );

    if (itemIndex < 0) {
      state.currentOverridenLastCrs.push(overridenLastCr);
    } else {
      state.currentOverridenLastCrs[itemIndex] = overridenLastCr;
      state.currentOverridenLastCrs = [...state.currentOverridenLastCrs];
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};