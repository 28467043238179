
import { useService } from "@/setup/connectClient";
import { FullProjectController } from "@socotec.io/socio-grpc-api/connect/services/production_unit_lister/lister_pb";
import { CaseController } from "@socotec.io/socio-grpc-api/connect/services/rapsosps_back/projects_pb";
import Operation from "@/models/Operation.js";
import RapsoSPSCase from "@/models/RapsoSPSCase.js";
import { Case, Client } from "@socotec.io/socio-vue-components";

const fullProjectClient = useService(FullProjectController);
const caseClient = useService(CaseController);

const state = {
  currentCase: null,
};

const getters = {
  getCurrentCase: (state) => {
    return state.currentCase;
  },
  getCurrentCaseProjects: (state) => {
    return Operation.query().where("caseNumber", state.currentCase.$id).all();
  },
};

const actions = {
  /**
   * Fetch case list
   * @param [_]
   * @param metadata
   * @returns {Promise<number>}
   */
  async fetchCases(context, metadata) {
    const response = await fullProjectClient.list({}, { headers: metadata });
    const results = response.results;
    results.forEach((result) => {
      Client.createFromListerData(result);
      Case.createFromListerData(result);
      Operation.createFromListerData(result);
    });
    return response.count;
  },

  /**
   * Retrieve case by case number
   * @param commit
   * @param caseNumber
   * @returns {Promise<RapsoSPSCase>}
   */
  async retrieveCase({ commit }, caseNumber) {
    const responseSpsBack = await caseClient.retrieveCaseByCaseNumber({ caseNumber });

    const result = await RapsoSPSCase.insertOrUpdate({ data: responseSpsBack });

    commit("SET_CURRENT_CASE", result.rapsoSPSCases[0]);

    return result.rapsoSPSCases[0];
  },

  /**
   * Update case (Rapsosps back field ONLY)
   * @param _
   * @param caseData {RapsoSPSCase}
   * @returns {Promise<void>}
   */
  async updateCase(_, caseData) {
    await caseClient.update({
      uuid: caseData.caseUuid,
      caseDescription: caseData.caseDescription,
      deploymentNote: caseData.deploymentNote,
    });

    await RapsoSPSCase.insertOrUpdate({
      data: caseData,
    });
  },
};

const mutations = {
  SET_CURRENT_CASE: (state, newCase) => {
    state.currentCase = newCase;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};