
import { Batch, DefaultBatch } from "@socotec.io/socio-vue-components";
import { useService } from "@/setup/connectClient";
import { BatchController, CustomizedBatchController } from "@socotec.io/socio-grpc-api/connect/services/batch_management/batch_pb";
import { PgcNodeController } from "@socotec.io/socio-grpc-api/connect/services/rapsosps_back/referencial_pb";
import { SERVICE_ID } from "@/constants";

const batchClient = useService(BatchController);
const customizedBatchClient = useService(CustomizedBatchController);
const pgcNodeClient = useService(PgcNodeController);

const SET_BATCHES_COUNT = "SET_BATCHES_COUNT";
const SET_DEFAULT_BATCHES_LOADED = "SET_DEFAULT_BATCHES_LOADED";
const ADD_BATCHES_COUNT = "ADD_BATCHES_COUNT";
const SET_PROJECT_BATCHES_LOADED = "SET_PROJECT_BATCHES_LOADED";

const state = {
  projectBatchesCount: 0,
  isDefaultBatchLoaded: false,
  isProjectBatchLoaded: false,
};

const getters = {
  getProjectBatchesCount: (state) => {
    return state.projectBatchesCount;
  },
  isDefaultBatchLoaded: (state) => {
    return state.isDefaultBatchLoaded;
  },
  isProjectBatchLoaded: (state) => {
    return state.isProjectBatchLoaded;
  },
};

const actions = {
  // FETCH
  async fetchDefaultBatches({ commit }, filtersData) {
    try {
      commit(SET_DEFAULT_BATCHES_LOADED, false);
      const response = await batchClient.list({}, {
        headers: {
          filters: JSON.stringify({
            service_id: SERVICE_ID,
            ...filtersData,
          }),
        },
      });
      commit(SET_DEFAULT_BATCHES_LOADED, true);
      return DefaultBatch.insert({
        data: response.results,
      });
    } catch (err) {
      console.error("Error while fetching default batches: ", err);
    }
  },
  async fetchProjectBatches({ commit }, metadata) {
    try {
      commit(SET_DEFAULT_BATCHES_LOADED, false);
      commit(SET_PROJECT_BATCHES_LOADED, false);
      const response = await customizedBatchClient.list({}, { headers: metadata });
      const resultList = response.results;

      commit(SET_BATCHES_COUNT, resultList.length);
      commit(SET_PROJECT_BATCHES_LOADED, true);

      return Batch.insert({
        data: resultList,
      });
    } catch (err) {
      console.error("Error while fetching project batches: ", err);
    }
  },
  // SET
  async createBatch({ commit }, batchToCreate) {
    batchToCreate.isActivated = true;
    try {
      const response = await customizedBatchClient.create(batchToCreate);
      const newBatch = response;
      commit(ADD_BATCHES_COUNT, 1);
      const dataInserted = await Batch.insert({
        data: newBatch,
      });
      return dataInserted;
    } catch (err) {
      console.error("Error while creating batch: ", err);
    }
  },

  async updateBatch(_, batchToUpdate) {
    batchToUpdate.isActivated = true;
    try {
      const response = await customizedBatchClient.update(batchToUpdate);
      const updatedBatch = response;
      const dataUpdated = await Batch.update({
        where: batchToUpdate.uuid,
        data: updatedBatch,
      });
      return dataUpdated.uuid;
    } catch (err) {
      console.error("Error while updating batch: ", err);
    }
  },

  async deleteBatch({ commit }, { batch, projectUuid }) {
    try {
      await customizedBatchClient.destroy({ uuid: batch.uuid });
      commit(ADD_BATCHES_COUNT, -1);
      Batch.delete(batch.uuid);

      await pgcNodeClient.unlinkBatch({
        batchUuid: batch.uuid,
        projectUuid: projectUuid,
      });
    } catch (err) {
      console.error("Error while deleting batch: ", err);
    }
  },
};

const mutations = {
  [SET_BATCHES_COUNT]: (state, newTotal) => {
    state.projectBatchesCount = newTotal;
  },
  [SET_DEFAULT_BATCHES_LOADED]: (state, value) => {
    state.isDefaultBatchLoaded = value;
  },
  [ADD_BATCHES_COUNT]: (state, number) => {
    state.projectBatchesCount += number;
  },
  [SET_PROJECT_BATCHES_LOADED]: (state, value) => {
    state.isProjectBatchLoaded = value;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};