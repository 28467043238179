
import { User, Agency, AgencyGroup } from "@socotec.io/socio-vue-components";
import { useService } from "@/setup/connectClient";
import { AGENCY_EXCLUDE_VALIDATION } from "@/constants";
import { UserController } from "@socotec.io/socio-grpc-api/connect/services/rapsosps_back/user_pb";
import { AgencyController } from "@socotec.io/socio-grpc-api/connect/services/user_management/entities_pb";
import { UserController as UserManagementController } from "@socotec.io/socio-grpc-api/connect/services/user_management/user_pb";

const userClient = useService(UserController);
const agencyClient = useService(AgencyController);
const userManagementClient = useService(UserManagementController);

const state = {
  userFromUserManagement: {},
};

const getters = {
  getCurrentUser: () => {
    return User.query()
      .where("isCurrentUser", true)
      .with("agencyDatas.agencyGroupDatas")
      .first();
  },
  getCurrentUserFromUserManagement: (state) => {
    return state.userFromUserManagement;
  },
  /**
   * Get user by email
   * @param email
   * @returns {User|null}
   */
  getUserByUserManagementUuid: () => (usermanagementUuid) => {
    return User.query().where("usermanagementUuid", usermanagementUuid).first();
  },
  isCurrentUserTheProjectManager: (state, getter, rootState, rootGetters) => {
    return (
      getter?.isDev ||
      (rootGetters["operation/getOperation"] &&
        rootGetters["operation/getOperation"].managerUsermanagementUuid ===
          getter?.getCurrentUser?.usermanagementUuid)
    );
  },
   isCurrentUserTheProjectSubstitute: (state, getter, rootState, rootGetters) => {
    return (
      getter?.isDev ||
      (rootGetters["operation/getOperation"] &&
        rootGetters["operation/getOperation"].substituteManagerUsermanagementUuid ===
          getter?.getCurrentUser?.usermanagementUuid)
    );
  },
  isTechnicalDirection: (state, getter) => {
    return getter?.isDev || getter?.getCurrentUser.isTechnicalDirection();
  },
  isDev: (state, getter) => {
    return (
      getter?.getCurrentUserFromUserManagement?.groups?.includes(
        "Developer"
      ) ||
      getter?.getCurrentUserFromUserManagement?.groups?.includes("SocioDev")
    );
  },
  isCurrentUserManagerOrSubstitute: (state, getter, rootState, rootGetters) => {
    const operation = rootGetters["operation/getOperation"];
    const user = getter?.getCurrentUser;
    return (
      getter?.isDev ||
      (operation &&
        user &&
        [
          operation.managerUsermanagementUuid,
          operation.substituteManagerUsermanagementUuid,
        ].includes(user.usermanagementUuid))
    );
  },
  isAgencyManager: (_, getters, __, rootGetters) => {
    const currentUser = getters?.getCurrentUser;
    const operationAgency = rootGetters["operation/getAgency"];
    if (
      currentUser?.isAgencyManager() &&
      currentUser.agencyDatas &&
      operationAgency
    ) 
      return operationAgency.code == currentUser.agencyDatas.code;
    
    return false;
  },
  isActivityDirector: (_, getters, __, rootGetters) => {
    const operationAgency = rootGetters["operation/getAgency"];
    const currentUser = getters?.getCurrentUser;
    if (
      currentUser?.isActivityDirector() &&
      currentUser?.agencyDatas &&
      operationAgency
    )
      return operationAgency.agencyGroup == currentUser.agencyDatas.agencyGroup;
    return false;
  },
  isCurrentUserCanUpdate: (_, getters, __, rootGetters) => {
    const operationAgency = rootGetters["operation/getAgency"];
    if (!operationAgency) return getters.isDev;
    let conditions;
    if (AGENCY_EXCLUDE_VALIDATION.includes(operationAgency.code)) {
      // hs: if agency is national check only roles else check roles and condition
      conditions = [
        getters.getCurrentUser.isDev(),
        getters.getCurrentUser.isAgencyManager(),
        getters.getCurrentUser.isActivityDirector(),
        getters.isCurrentUserTheProjectManager,
      ];
    } else {
      conditions = [
        getters.isDev,
        getters.isAgencyManager,
        getters.isActivityDirector,
        getters.isCurrentUserTheProjectManager,
      ];
    }
    return conditions.includes(true);
  },
  isTester: (state, getters) => {
    return getters?.isDev || getters?.getCurrentUser?.isInGroup("Tester");
  }
};

const actions = {
  async initializeCurrentUser({ dispatch, commit }) {
    // Fetch user, his agency and agency group
    const userResult = await dispatch("fetchCurrentUser");
    const user = userResult.users[0];
    try {
      await commit("CURRENT_USER_FROM_USER_MANAGEMENT", {});
      const userFromUserManagementResult = await dispatch(
        "retrieveUserFromUserManagement",
        user.usermanagementUuid
      );
      // FIXME: ARx 30/12/2022 –  Get by user management because SPS users permissions are broken
      await commit(
        "CURRENT_USER_FROM_USER_MANAGEMENT",
        userFromUserManagementResult
      );
    } catch (error) {
      console.error("Fail to retrieve user from user management:\n", error);
    }
    const agencyResult = await dispatch("fetchUserAgencyAndAgencyGroup", {
      uuid: user.usermanagementUuid,
    });
    if (!agencyResult) {
      return;
    }
    await User.update({
      where: (user) => {
        return user.isCurrentUser;
      },
      data: { agency: agencyResult.agency[0].uuid },
    });
  },

  async fetchUserAgencyAndAgencyGroup(context, { uuid }) {
    try {
      const response = await agencyClient.retrieveUserAgency({ uuid });
      const agency = response;
      if (!agency.uuid) {
        return;
      }
      if (agency.agencyGroup) {
        await AgencyGroup.insert({
          data: agency.agencyGroup,
        });
        // Only store agencyGroup relation
        agency.agencyGroup = agency.agencyGroup.uuid;
      }
      return await Agency.insert({
        data: agency,
      });
    } catch (err) {
      console.log(err);
    }
  },

  async fetchCurrentUser({ rootGetters }) {
    try {
      const response = await userClient.retrieveByUsermanagementUuid({
        uuid: rootGetters["oidc/oidcUser"].usermanagementUuid,
      });

      const userData = response;
      return await User.insert({
        data: { ...userData, isCurrentUser: true },
      });
    } catch (err) {
      console.log(err);
    }
  },

  async retrieveUserFromUserManagement(context, usermanagementUuid) {
    const response = await userManagementClient.retrieve({ uuid: usermanagementUuid });
    return response;
  },

  async getUsersFromUuids(context, uuids) {
    const metadata = {
      filters: JSON.stringify({
        is_in_usermanagement_uuids: uuids,
      }),
    };
    const response = await userClient.list({}, { headers: metadata });
    const { results } = response;
    await User.insertOrUpdate({
      data: results,
    });
    return results;
  },

  async partialUpdateUser(context, datas) {
    try {
      const response = await userClient.partialUpdate({
        uuid: datas.uuid,
        signature: datas.signature,
        PartialUpdateFields: ["signature"],
      });
      return await User.update({
        data: { ...response },
      });
    } catch (err) {
      console.log(err);
    }
  },

  async searchUser(context, text) {
    const metadata = {
      filters: JSON.stringify({ search: text }),
    };
    const response = await userManagementClient.list({}, { headers: metadata });
    const users = response.results;
    return users.map((user) => new User(user));
  },

  /**
   * Fetch agency by code
   * @param _
   * @param code {string} Agency code
   * @returns {Promise<Agency>}
   */
  async fetchAgencyByCode(_, code) {
    const response = await agencyClient.retrieveAgencyByCode({ code });
    const agency = response;

    if (agency.uuid === "") {
      throw new Error("There is no agency with this code");
    }
    if (agency.agencyGroup) {
      await AgencyGroup.insert({
        data: agency.agencyGroup,
      });
      // Only store agencyGroup relation
      agency.agencyGroup = agency.agencyGroup.uuid;
    }
    await Agency.insert({
      data: agency,
    });

    return agency;
  },
};

const mutations = {
  CURRENT_USER_FROM_USER_MANAGEMENT: (state, user) => {
    state.userFromUserManagement = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};