
import { useService } from "@/setup/connectClient";
import { NotificationController, UserNotificationController } from "@socotec.io/socio-grpc-api/connect/services/notification_management/notification_pb";

const notificationClient = useService(NotificationController);
const userNotificationClient = useService(UserNotificationController);

const state = {};

const getters = {};

const actions = {
  async fetchUserNotificationEmailStatus(context, objectRelatedUuid) {
    const response = await userNotificationClient.fetchUserEmailStatus({
      objectRelatedUuid: objectRelatedUuid
    });
    
    return response.results;
  },

  async resendUserNotificationsEmail(_, { objectRelatedUuid, usermanagementUuids }) {
    return await notificationClient.reSendNotification({
      objectRelatedUuid: objectRelatedUuid,
      recipientUsers: usermanagementUuids
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};