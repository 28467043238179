
import { useService } from "@/setup/connectClient";
import { CompanyController } from "@socotec.io/socio-grpc-api/connect/services/contributors/contributors_pb";
import { Company } from "@socotec.io/socio-vue-components";

const client = useService(CompanyController);

const state = {};

const getters = {};

const actions = {
  async createCompany(context, company) {
    try {
      const response = await client.create(company);

      const newCompany = new Company(response);
      newCompany.$save();
      return newCompany;
    } catch (error) {
      console.error("Error creating company:", error);
      throw error;
    }
  },

  async updateCompany(context, company) {
    try {
      const response = await client.update(company);

      return new Company(response);
    } catch (error) {
      console.error("Error updating company:", error);
      throw error;
    }
  },

  async searchCompany(context, { searchText, filters }) {
    try {
      const headers = {
        filters: JSON.stringify({ search: searchText, ...filters }),
      };

      const response = await client.list({}, { headers });
      
      return response.results.map((c) => new Company(c));
    } catch (error) {
      console.error("Error searching companies:", error);
      throw error;
    }
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};