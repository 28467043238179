
import { useService } from "@/setup/connectClient";
import { RapsoSPSTechnicalDoc } from "@socotec.io/socio-vue-components";
import { RapsoSPSTechnicalDocController } from "@socotec.io/socio-grpc-api/connect/services/referencial/referencial_services_pb";

const SET_LOADED = "SET_LOADED";

const client = useService(RapsoSPSTechnicalDocController);

async function checkIfDataNeedsUpdate(request, metadata) {
  try {
    const localStorageTechnicalDoc = localStorage.getItem("technicalDoc");
    if (!localStorageTechnicalDoc) return true;

    const { updated_at: storedDate } = JSON.parse(localStorageTechnicalDoc);
    const response = await client.getLastUpdateDate(request, { headers: metadata });
    const serverDate = new Date(response.lastUpdatedDate);

    return new Date(storedDate) <= serverDate;
  } catch (err) {
    console.error("Error while fetching technical document last updated date: ", err);
    return false;
  }
}

async function fetchAndStoreNewData(request, metadata, technicalDocType) {
  const response = await client.list(request, { headers: metadata });
  const results = response.results;
  if (technicalDocType === "risk") {
    localStorage.setItem("technicalDoc", JSON.stringify({
      updated_at: Date.now(),
      results: results,
    }));
  }

  return results;
}

const state = {
  loaded: false,
};

const getters = {
  isLoaded: (state) => {
    return state.loaded;
  },
  getByType: () => (type) => {
    return RapsoSPSTechnicalDoc.query().where("type", type);
  },
};

const actions = {
  async fetchTechnicalDoc({ commit }, { params, filtersData }) {
    try {
      commit(SET_LOADED, false);
      const request = params;

      const metadata = {
        filters: JSON.stringify({
          ...filtersData,
        }),
      };

      const technicalDocType = filtersData?.type;
      const shouldFetchNewData = technicalDocType === "risk" ? await checkIfDataNeedsUpdate(request, metadata) : true;
      let results = [];
      if (shouldFetchNewData) {
        results = await fetchAndStoreNewData(request, metadata, technicalDocType);
      } else {
        results = JSON.parse(localStorage.getItem("technicalDoc")).results;
      }

      commit(SET_LOADED, true);
      await RapsoSPSTechnicalDoc.deleteAll();
      return RapsoSPSTechnicalDoc.insert({
        data: results,
      });
    } catch (err) {
      console.error("Error while fetching technical document: ", err);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async createTechnicalDoc({ commit, dispatch }, formData) {
    try {
      formData.uuid = null;

      const response = await client.create(formData);
      RapsoSPSTechnicalDoc.insert({
        data: response,
      });
      return response;
    } catch (err) {
      const msg = err.message
        .substring(err.message.indexOf("RapsoSPS"))
        .split("'")[0];
      dispatch("notifications/showErrorNotification", msg, { root: true });
    }
  },
  // eslint-disable-next-line no-unused-vars
  async updateTechnicalDoc({ commit, dispatch }, formData) {
    try {
      const response = await client.update(formData);
      return RapsoSPSTechnicalDoc.update({
        where: response.uuid,
        data: response,
      });
    } catch (err) {
      const msg = err.message
        .substring(err.message.indexOf("RapsoSPS"))
        .split("'")[0];
      dispatch("notifications/showErrorNotification", msg, { root: true });
    }
  },
};

const mutations = {
  [SET_LOADED]: (state, value) => {
    state.loaded = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};