
import { useService } from "@/setup/connectClient";
import {
  ReferencialNode,
  RapsoSPSNode,
  utils,
} from "@socotec.io/socio-vue-components";
import DiuoExtra from "@/models/DIUOExtra";
import Pgc from "@/models/PGC";
import Diuo from "@/models/DIUO";
import {
  formatModelName,
  metadataAsString,
  flatNodes,
  setConditionList,
} from "@/utils/referencialService";
import {
  ReferencialNodeController, RapsoSPSNodeController,
  ReferencialUpdateController, CSVExportReferencialController,
} from "@socotec.io/socio-grpc-api/connect/services/referencial/referencial_services_pb";
import {
  DiuoNodeController, PgcNodeController, InitialConceptionNodeController, DiuoNodeExtraController
} from "@socotec.io/socio-grpc-api/connect/services/rapsosps_back/referencial_pb"

const referencialNodeClient = useService(ReferencialNodeController);
const rapsoSPSNodeClient = useService(RapsoSPSNodeController);
const referencialUpdateClient = useService(ReferencialUpdateController);
const csvExportReferencialClient = useService(CSVExportReferencialController);
const diuoNodeClient = useService(DiuoNodeController);
const diuoNodeExtraClient = useService(DiuoNodeExtraController);


const controllerMapping = {
  DiuoNode: DiuoNodeController,
  PgcNode: PgcNodeController,
  InitialConceptionNode: InitialConceptionNodeController,
}

const socioReferencialUtils = utils.referencialUtils;

const state = {
  referencialVersion: {
    diuo: null,
    pgc: null,
    initialConception: null,
  },
};

const getters = {
  getTree:
    () =>
      (referencial, displayIsArchived = true) => {
        return ReferencialNode.query()
          .where((node) => {
            if (!displayIsArchived && node.isArchived) return false;
            return (
              node.referencialName === referencial && node.isDisplay === true
            );
          })
          .with("spsNode")
          .get()
          .sort((a, b) => {
            return a.path.localeCompare(b.path, undefined, {
              numeric: true,
              sensitivity: "base",
            });
          });
      },
  getDiuoExtra: () => (diuoNode, diuoType) => {
    return DiuoExtra.query()
      .where((node) => {
        return node.diuoNode === diuoNode && node.diuoType === diuoType;
      })
      .get();
  },
  getDocumentAttachedUuid: () => (files) => {
    if (!files || files.length === 0) return [];
    let fileUuids = files.map((file) => file.uuid);
    return fileUuids;
  },
  getReferencialVersion: (state) => (referencialName) => {
    return state.referencialVersion[referencialName];
  },
  getOtherNodeDataAttached: () => (node) => {
    if (node.referencialName === "pgc") {
      return Pgc.query().where("spsNode", node.spsNode.uuid).first();
    } else {
      return Diuo.query().where("spsNode", node.spsNode.uuid).first();
    }
  },
  isReferencialNodesFetched:
    (state, getters, rootState) => (referencialName) => {
      return rootState.entities.referencialNodes[referencialName];
    },

  diuoParams: (state, getter, rootState, rootGetters) => {
    return {
      versionName: "CURRENT",
      versionNumber: 1,
      applicationName: "RAPSOSPS_FRANCE",
      referencialType: "PROJECT",
      referencialName: "RAPSOSPS_IU",
      projectUuid: rootGetters["operation/getOperationUuid"],
    };
  },
  pgcParams: (state, getter, rootState, rootGetters) => {
    return {
      versionName: "CURRENT",
      versionNumber: 1,
      applicationName: "RAPSOSPS_FRANCE",
      referencialType: "PROJECT",
      referencialName: "RAPSOSPS_OC",
      projectUuid: rootGetters["operation/getOperationUuid"],
    };
  },
};

const actions = {
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤ LIST ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤

  async fetchReferencialVersion({ commit }, data) {
    const response = await referencialNodeClient.version(data.requestParams);
    commit("SET_REFERENCIAL_VERSION", {
      versionData: response,
      referencialFetched: data.referencialFetched,
    });
  },

  async fetchNodes({ dispatch }, data) {
    await dispatch("fetchRapsoSPSNodes", data);
    await dispatch("fetchReferencialNodes", data);
  },

  async fetchReferencialNodes({ dispatch }, data) {
    const { metadata = {}, requestParams, referencialFetched } = data;

    const metadataStr = metadataAsString(metadata);

    const response = await referencialNodeClient.list(requestParams, {
      headers: metadataStr,
    });

    let flattenNodes = flatNodes(response.results, referencialFetched);

    /* INFO - TT - 19/10/2021: In case where we apply search filter we need
    to delete initial referencial nodes and then insert referencial nodes fetched
    following filter from bakend. Cannot use create method of vuexorm model as
    this model is use by many components for others usages*/

    const { search } = metadata.filters || {};

    if (search) {
      await ReferencialNode.delete(
        (node) => node.referencialName === referencialFetched
      );
      flattenNodes = flattenNodes.map((nodes) => ({
        ...nodes,
        isOpen: true,
        isDisplay: true,
      }));
    }

    await ReferencialNode.insert({ data: flattenNodes });
    ReferencialNode.commit((state) => {
      state[data.referencialFetched] = true;
    });

    /* INFO - TT - 12/10/2021: Below code allows to fetch users data
     from their emails as they are necessary in referencial administration*/
    const modifierUsersUuid = await ReferencialNode.query()
      .where("modifiedByUuid", (value) => value !== "")
      .get()
      .map((referencialNode) => referencialNode.modifiedByUuid);
    const uniqueUsersUuids = new Set(modifierUsersUuid);
    if (uniqueUsersUuids.size !== 0) {
      await dispatch("user/getUsersFromUuids", Array.from(uniqueUsersUuids), {
        root: true,
      });
    }
  },

  async fetchRapsoSPSNodes(context, data) {
    const response = data.withSpsNodeTechnicalDoc
      ? await rapsoSPSNodeClient.listWithTechnicalDocs(data.requestParams)
      : await rapsoSPSNodeClient.list(data.requestParams);

    const results = response.results;
    await RapsoSPSNode.insert({ data: results });
  },

  async fetchFlatFilteredReferencialNodes(
    context,
    { requestParams, metadata, insertData = true }
  ) {
    const response = await referencialNodeClient.flatList(requestParams, {
      headers: metadata,
    });

    if (!insertData) {
      return response.results;
    }
    await ReferencialNode.insert({ data: response.results });
  },

  // On rapsosps-back, fetch the nodes: DiuoNode, PgcNode, InitialConceptionNode
  async fetchSpsBackNodeEditedDatas({ dispatch }, { model, metadata }) {
    const modelBackendFormat = formatModelName(model.entity);
    const client = useService(
      controllerMapping[modelBackendFormat]
    );
    const response = await client.list({}, { headers: metadata });

    const results = response.results;

    await model.insert({ data: results });

    if (model.entity === "diuo") {
      await dispatch("fetchDiuoExtraDatas");
    }
  },

  async fetchDiuoExtraDatas({ dispatch, rootGetters }) {
    const metadata = {
      filters: JSON.stringify({
        diuo_node__project_uuid: rootGetters["operation/getOperationUuid"],
      }),
    };
    const response = await diuoNodeExtraClient.list({}, { headers: metadata });

    const results = response.results;

    for (const diuoNodeExtra of results) {
      const diuoNodeExtraFiles = diuoNodeExtra.files.filter(
        (file) => file && file !== ""
      );
      if (diuoNodeExtraFiles.length === 0) continue;
      const documents = await dispatch(
        "fetchDocumentAttachedByUuid",
        diuoNodeExtraFiles.join(",")
      );
      diuoNodeExtra.files = documents;
    }
    await DiuoExtra.insert({ data: results });
  },

  async fetchDocumentAttachedByUuid({ dispatch }, files) {
    const metadata = {
      filters: JSON.stringify({
        uuid: files,
      }),
    };
    return await dispatch(
      "document/fetchDocumentList",
      { metadata, createData: false },
      {
        root: true,
      }
    );
  },

  async fetchReferencialNodeDescendants(
    context,
    { params = {}, metadata = {} }
  ) {
    Object.keys(metadata).forEach((key) => {
      if (typeof metadata[key] === "object") {
        metadata[key] = JSON.stringify(metadata[key]);
      } else {
        metadata[key] = metadata[key].toString();
      }
    });

    const response = await referencialNodeClient.list(params, {
      headers: metadata,
    });
    return response.results;
  },

  async fetchReferencialNodeParents(context, { params = {}, metadata = {} }) {
    const response = await referencialNodeClient.getNodesWithParents(params, {
      headers: metadata,
    });
    return response.results;
  },

  async retrieveReferencialNode(context, { uuid }) {
    const response = await referencialNodeClient.retrieve({ uuid });
    return response;
  },

  async retrieveRapsoSPSNode(context, uuid) {
    const rapsospsNodeResponse = await rapsoSPSNodeClient.retrieve({ uuid });

    return rapsospsNodeResponse;
  },

  async getModifiedReferencialNodes({ rootGetters }, params) {
    const currentUser = rootGetters["user/getCurrentUser"];
    params.modifiedByUuid = currentUser.usermanagementUuid;
    const response = await referencialNodeClient.getModified(params);
    return response.results;
  },

  async getLastReferencialExportDate(context, params) {
    const response = await csvExportReferencialClient.getDateLastReferencialExport(
      params
    );
    return response.exportDate;
  },

  async getReferencialLastUpdateDate(context, params) {
    const response = await referencialUpdateClient.lastUpdate(params);
    return response.lastUpdated;
  },

  async createReferencialNode({ rootGetters, dispatch }, referencialNode) {
    let rapsoSpsNode = null;

    if (referencialNode.objectId) {
      const nationalRapsoSPSNode = await dispatch(
        "retrieveRapsoSPSNode",
        referencialNode.objectId
      );
      const rapsoSpsNodeEmpty = await dispatch(
        "createRapsoSPSNode",
        nationalRapsoSPSNode
      );
      rapsoSpsNode = await dispatch(
        "retrieveRapsoSPSNode",
        rapsoSpsNodeEmpty.uuid
      );
    } else {
      rapsoSpsNode = await dispatch(
        "createRapsoSPSNode",
        referencialNode.spsNode || {}
      );
    }

    const rapsoSpsNodesInserted = await RapsoSPSNode.insert({
      data: rapsoSpsNode,
    });
    referencialNode.objectId = rapsoSpsNode.uuid;

    referencialNode.modifiedByUuid =
      rootGetters["user/getCurrentUser"].usermanagementUuid;

    const referencialNodeResponse = await referencialNodeClient.create(
      referencialNode
    );
    const result = referencialNodeResponse;

    result.spsNode = rapsoSpsNodesInserted.rapsoSPSNodes[0];
    result.referencialName = referencialNode.referencialName;
    result.isDisplay = true;

    await ReferencialNode.insert({
      data: result,
    });
    // Update the children/numchild of the parent node to have the toggleExpandBtn displayed in UI
    const parentNode = await socioReferencialUtils.getParentNode(result);
    if (parentNode) {
      socioReferencialUtils.updateNodeChildren(parentNode);
      parentNode.isOpen = true;
      parentNode.$save();
    }
  },

  async createRapsoSPSNode(context, rapsoSpsNode) {
    const response = await rapsoSPSNodeClient.create(rapsoSpsNode);
    return response;
  },

  async updateRapsoSPSNode(context, rapsoSpsNode) {
    const response = await rapsoSPSNodeClient.update(rapsoSpsNode);

    await RapsoSPSNode.update({
      where: rapsoSpsNode.uuid,
      data: response,
    });
  },

  async saveReferencialNode({ rootGetters }, referencialNode) {
    setConditionList(referencialNode, "conditionalTitles", referencialNode);
    setConditionList(referencialNode, "conditionalDisplay", referencialNode);

    referencialNode.modifiedByUuid =
      rootGetters["user/getCurrentUser"].usermanagementUuid;
    const response = await referencialNodeClient.update(referencialNode);
    await ReferencialNode.update({
      where: referencialNode.uuid,
      data: {
        label: response.label,
        description: response.description,
        modifiedByUuid: response.modifiedByUuid,
      },
    });
  },

  // On rapsosps-back, save the DiuoNode, PgcNode, InitialbConceptionNode
  async saveSpsBackNode({ getters }, data) {
    const fileUuids = getters.getDocumentAttachedUuid(data.form.files);
    const modelBackendFormat = formatModelName(data.model.entity);
    const client = useService(
      controllerMapping[modelBackendFormat]
    );

    const requestData = {
      ...data.form,
      objectStatus: data.form.objectStatus ?? "",
      spsNode: data.spsNodeUuid,
      projectUuid: data.projectUuid,
      files: fileUuids,
    };

    let result = null;
    if (requestData.uuid) {
      const response = await client.update(requestData);
      result = response;
    } else {
      const response = await client.create(requestData);
      result = response;
      data.form.uuid = result.uuid;
    }
    return await data.model.insertOrUpdate({
      data: result,
    });
  },

  async saveDiuoExtraDatas({ getters, rootGetters }, data) {
    const fileUuids = getters.getDocumentAttachedUuid(data.files);
    const requestData = { ...data, files: fileUuids };

    // Add project_uuid in metadata because permissions in backend need it
    const metadata = {
      filters: JSON.stringify({
        project_uuid: rootGetters["operation/getOperationUuid"],
      }),
    };

    let result = null;
    if (requestData.uuid) {
      const response = await diuoNodeExtraClient.update(requestData, {
        headers: metadata,
      });
      result = response;
    } else {
      const response = await diuoNodeExtraClient.create(requestData, {
        headers: metadata,
      });
      result = response;
    }

    result.files = data.files;
    await DiuoExtra.insertOrUpdate({ data: result });
  },

  async createProjectReferencial(context, requestParams) {
    await referencialNodeClient.createProjectReferencial(requestParams);
  },

  async updateReferencialVersionName({ commit }, data) {
    const response = await referencialNodeClient.updateVersionName(
      data.requestParams
    );
    commit("SET_CUSTOM_REFERENCIAL_NAME", {
      versionData: response,
      referencialFetched: data.referencialFetched,
    });
  },

  async moveReferencialNode({ rootGetters }, { referencialNodeUuid, form }) {
    const user = rootGetters["user/getCurrentUser"];
    return referencialNodeClient.updateMove({
      target: form.target,
      code: form.code,
      uuid: referencialNodeUuid,
      modifiedByUuid: user.usermanagementUuid,
    });
  },

  async postDraftToCurrent({ rootGetters }, data) {
    const user = rootGetters["user/getCurrentUser"];
    data.migratedByUuid = user.usermanagementUuid;
    const response = await referencialUpdateClient.postDraftToCurrent(data);
    return response;
  },

  async duplicateNodeWithChildren({ state, dispatch }, requestData) {
    const params = {
      ...requestData,
      nodeToCopyUuid: requestData.nodeToCopy.uuid,
      referencialName: requestData.nodeToCopy.referencialName,
    };
    const response = await referencialNodeClient.duplicateNode(params);
    const nodes = response.results;
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      // Display the first parent node duplicated
      if (i === 0) {
        node.isDisplay = true;
      }
      // Set numchild to node to have the toggleExpandBtn displayed in UI
      if (nodes[i + 1] && nodes[i + 1].level > node.level) {
        node.numchild = nodes.length - (i + 1);
      }
      // MS - INFO - 13/10/2021 - perf can be improved if we fetch all RapsoSPSNode in one call
      let rapsoSPSNode = await dispatch("retrieveRapsoSPSNode", node.objectId);
      rapsoSPSNode.referencialNode = node.uuid;
      rapsoSPSNode = new RapsoSPSNode(rapsoSPSNode);
      node.spsNode = rapsoSPSNode;
      node.referencialName = requestData.nodeToCopy.referencialName;
    }
    const newInserted = await ReferencialNode.insert({ data: nodes });

    // Update the children/numchild of the new duplicated referencial nodes,
    // MS - INFO - 22/11/2021 - we can avoid  this if backend serializer return the node updated numchild and children array
    for (const node of newInserted.referencialNodes) {
      socioReferencialUtils.updateNodeChildren(node);
      const parentNode = await socioReferencialUtils.getParentNode(node);
      if (parentNode) {
        socioReferencialUtils.updateNodeChildren(parentNode);
      }
    }

    // Duplicate sps-back Diuo nodes attached
    const requestParams = {
      ...state.referencialVersion[requestData.nodeToCopy.referencialName],
      parentNode: requestData.nodeToCopy.uuid,
    };
    delete requestParams.uuid;

    let sourceNodes = await dispatch("fetchFlatFilteredReferencialNodes", {
      requestParams,
      metadata: {},
      insertData: false,
    });
    const parentRootNode = await ReferencialNode.find(
      requestData.nodeToCopy.uuid
    );
    const sourceNodesUuid = sourceNodes.map((node) => node.objectId);
    sourceNodesUuid.unshift(parentRootNode.objectId);
    const targetNodesUuid = nodes.map((node) => node.objectId);
    await dispatch("duplicateDiuoNodes", {
      sourceNodesUuid,
      targetNodesUuid,
    });
  },

  async duplicateNodeReferencialWithChildren({ dispatch }, requestData) {
    const params = {
      ...requestData,
      nodeToCopyUuid: requestData.nodeToCopy.uuid,
      referencialName: requestData.nodeToCopy.referencialName,
    };
    const response = await referencialNodeClient.duplicateNode(params);
    const nodes = response.results;
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      // Display the first parent node duplicated
      if (i === 0) {
        node.isDisplay = true;
      }
      // Set numchild to node to have the toggleExpandBtn displayed in UI
      if (nodes[i + 1] && nodes[i + 1].level > node.level) {
        node.numchild = nodes.length - (i + 1);
      }
      // MS - INFO - 13/10/2021 - perf can be improved if we fetch all RapsoSPSNode in one call
      let rapsoSPSNode = await dispatch("retrieveRapsoSPSNode", node.objectId);
      rapsoSPSNode.referencialNode = node.uuid;
      rapsoSPSNode = new RapsoSPSNode(rapsoSPSNode);
      node.spsNode = rapsoSPSNode;
      node.referencialName = requestData.nodeToCopy.referencialName;
    }

    for (const node of nodes) {
      node.manuallyCreated = true;
      node.referencialName = "pgc";

    }
    const newInserted = await ReferencialNode.insert({
      data: nodes,
    });
    for (const node of newInserted.referencialNodes) {
      // Update the children/numchild of the parent node to have the toggleExpandBtn displayed in UI
      const parentNode = await socioReferencialUtils.getParentNode(node);
      if (parentNode) {
        await socioReferencialUtils.updateNodeChildren(parentNode);
        await parentNode.$save();
      }
    }
    const nodeSelected = params.nodeSelected;
    if (nodeSelected.children) {
      nodeSelected.children = {
        "rapsoSPSNodes": newInserted.rapsoSPSNodes[0],
        "referencialNodes": newInserted.referencialNodes[0]
      };
    } else {
      nodeSelected.children.rapsoSPSNodes.push(newInserted.rapsoSPSNodes[0]);
      nodeSelected.children.referencialNodes.push(newInserted.referencialNodes[0]);
    }
    const parentNode = await socioReferencialUtils.getParentNode(nodeSelected);
    if (parentNode) {
      socioReferencialUtils.updateNodeChildren(nodeSelected);
      await nodeSelected.$save();
    }

  },

  async duplicateDiuoNodes(
    { dispatch, rootGetters },
    { sourceNodesUuid, targetNodesUuid }
  ) {
    const response = await diuoNodeClient.duplicateNode({
      sourceNodesUuid,
      targetNodesUuid,
      projectUuid: rootGetters["operation/getOperationUuid"],
    });

    let results = response.results;
    if (results.length === 0) return;
    await Diuo.insert({ data: results });
    await dispatch("fetchDiuoExtraDatas");
  },

  async updateNodesWithMandatoryMissionCodes(
    { rootState },
    { referencialFetched, analysisNodes }
  ) {
    // INFO - MS - 15/02/22: For PGC, this set isSelected to true in ReferencialNode
    // if his RapsoSPSNode has a mandatoryMissionCodes that match the project mission code
    // when in configurator mode
    if (referencialFetched !== "pgc") return;

    const nodeWithmandatoryMissions = analysisNodes.filter(
      (node) =>
        node.spsNode.mandatoryMissionCodes.length > 0 &&
        node.spsNode.mandatoryMissionCodes.some(
          (code) => code === rootState.operation.currentOperation.mission
        )
    );

    await ReferencialNode.update({
      where: (node) =>
        nodeWithmandatoryMissions.find((n) => n.uuid === node.uuid),
      data: { isSelected: true },
    });
  },

  async updateTreeAfterDragNDrop({ getters, rootGetters }, data) {
    let parentTargetNode = await socioReferencialUtils.getParentNode(
      data.target
    );
    let parentSourceNode = await socioReferencialUtils.getParentNode(
      data.source
    );

    const rootNodeUuid = getters.getReferencialVersion(
      data.referencialName
    ).rootNodeUuid;

    if (!parentTargetNode) {
      parentTargetNode = {
        uuid: rootNodeUuid,
        path: "root",
      };
    }

    if (data.isDropAfterLastChild) {
      const pathSplitted = data.target.path.split(".");
      pathSplitted.splice(
        pathSplitted.length - 1,
        1,
        (data.target.code + 1).toString()
      );
      const path = pathSplitted.join(".");
      data.target.code = data.target.code + 1;
      data.target.path = path;
    }

    // Update the source node path and code with the target
    await ReferencialNode.update({
      where: (node) =>
        node.referencialName === data.referencialName &&
        node.uuid === data.source.uuid,
      data: {
        path: data.target.path,
        code: data.target.code,
      },
    });

    // Get source node children
    const childrenSourceNode = flatNodes(
      data.source.children,
      data.referencialName
    );

    // Update source node children path and code related to the target
    childrenSourceNode.forEach((node) => {
      const referencialNode = ReferencialNode.find(node.uuid);
      referencialNode.path = referencialNode.path.replace(
        data.source.path,
        data.target.path
      );
      referencialNode.code += 1;
      referencialNode.$save();
    });

    // Get siblings node to update due to move source => target
    const nodeImpactedToUpdate = await ReferencialNode.query()
      .where((node) => {
        const targetPathToInt = parseInt(data.target.path.split(".").join(""));
        const currentNodePathToInt = parseInt(node.path.split(".").join(""));
        return data.isDropAfterLastChild
          ? false
          : node.uuid === data.target.uuid ||
          (node.referencialName === data.referencialName &&
            node.level === data.source.level &&
            (parentTargetNode.path === "root"
              ? true
              : node.path.startsWith(`${parentTargetNode.path}.`)) &&
            currentNodePathToInt > targetPathToInt);
      })
      .get();

    // Update path and code for each siblings node impacted by the move source => target
    nodeImpactedToUpdate.forEach((node) => {
      const children = flatNodes(node.children, data.referencialName).map(
        (n) => n.uuid
      );
      const childrenToUpdate = ReferencialNode.query()
        .where((n) => children.includes(n.uuid))
        .get();

      childrenToUpdate.forEach((child) => {
        const childPathSplit = child.path.split(".");
        childPathSplit.splice(node.level - 1, 1, (node.code + 1).toString());
        child.path = childPathSplit.join(".");
        child.$save();
      });

      const nodePathSplit = node.path.split(".");
      nodePathSplit.splice(node.level - 1, 1, (node.code + 1).toString());
      node.path = nodePathSplit.join(".");
      node.code += 1;
      node.$save();
    });

    // Increment/decrement children/numchild of source and target parents node if move is for example: 2.2 => 4.5
    if (parentTargetNode.uuid !== rootNodeUuid) {
      socioReferencialUtils.updateNodeChildren(parentTargetNode);
      socioReferencialUtils.updateNodeChildren(parentSourceNode);
    }

    // Make the call to backend at end for preserve UI drag n drop animation
    const user = rootGetters["user/getCurrentUser"];
    await referencialNodeClient.updateMove({
      uuid: data.source.uuid,
      target: parentTargetNode.uuid,
      code: data.target.code,
      modifiedByUuid: user.usermanagementUuid,
    });
  },

  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤ DELETE ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤

  async removeDiuoExtra({ rootGetters }, uuid) {
    const metadata = {
      filters: JSON.stringify({
        project_uuid: rootGetters["operation/getOperationUuid"],
      }),
    };

    await diuoNodeExtraClient.destroy({ uuid }, { headers: metadata });
    DiuoExtra.delete(uuid);
  },

  async removeReferencialNodeAndRelation({ dispatch, getters }, node) {
    // Check and remove node children before
    if (node.numchild > 0) {
      const childNodes = await ReferencialNode.query()
        .where((n) => {
          return (
            n.referencialName === node.referencialName &&
            n.path.startsWith(`${node.path}.`) &&
            node.level + 1 === n.level
          );
        })
        .with("spsNode")
        .get();
      for (const child of childNodes) {
        await dispatch("removeReferencialNodeAndRelation", child);
      }
    }

    // Call Destroy on µService Referencial for remove the ReferencialNode
    await dispatch("removeReferencialNode", node);

    // If "removeReferencialNode()" fail, the code below is not executed (because of throw new Error("referencialError"))
    // Update the numchild/children of the parent node
    const parentNode = await socioReferencialUtils.getParentNode(node);
    if (parentNode) {
      socioReferencialUtils.updateNodeChildren(parentNode);
    }

    // If a nodeAttached is find, call Destroy on RapsoSPSBack
    // for remove the PgcNode/DiuoNode(Extra) data
    const nodeAttached = getters.getOtherNodeDataAttached(node);
    if (nodeAttached) {
      await dispatch("removeReferencialNodeRelatedData", {
        modelName: node.referencialName,
        nodeAttached,
      });
    }
  },

  async removeReferencialNode(context, node) {
    try {
      await referencialNodeClient.destroy({ uuid: node.uuid });
    } catch {
      throw new Error("referencialError");
    }
    // if no error catched, remove data in frontend vuex-orm entities stores
    await ReferencialNode.delete(node.uuid);
    await RapsoSPSNode.delete(node.spsNode?.uuid);
  },

  async removeReferencialNodeRelatedData(context, { modelName, nodeAttached }) {
    try {
      // INFO - B.L - 29/06/2022 - Adds projct uuid in metadata for permission checking in sps back
      const metadata = {
        filters: JSON.stringify({
          project_uuid: nodeAttached.projectUuid,
        }),
      };
      const modelBackendFormat = formatModelName(modelName);
      const client = useService(
        controllerMapping[modelBackendFormat]
      );
      await client.destroy({ uuid: nodeAttached.uuid }, { headers: metadata });
    } catch {
      throw new Error("spsBackError");
    }

    // if no error catched, remove data in frontend vuex-orm entities stores
    if (modelName === "pgc") {
      await Pgc.delete((pgcNode) => pgcNode.spsNode === nodeAttached.spsNode);
      return;
    }
    await Diuo.delete(nodeAttached.spsNode);
    await DiuoExtra.delete(
      (diuoExtra) => diuoExtra.diuoNode === nodeAttached.uuid
    );
  },

  async archiveReferencialNode({ rootGetters }, referencialNode) {
    const user = rootGetters["user/getCurrentUser"];
    referencialNode.modifiedBy = user.usermanagementUuid;

    const response = await referencialNodeClient.archiveReferencialNode(
      referencialNode
    );

    return await ReferencialNode.update({
      where: response.uuid,
      data: {
        isArchived: response.isArchived,
      },
    });
  },
};

const mutations = {
  SET_REFERENCIAL_VERSION: (state, data) => {
    if (!data) {
      for (const version in state.referencialVersion) {
        state.referencialVersion[version] = null;
      }
      return;
    }
    state.referencialVersion[data.referencialFetched] = data.versionData;
  },
  SET_CUSTOM_REFERENCIAL_NAME: (state, data) => {
    state.referencialVersion[data.referencialFetched].customReferencialName =
      data.versionData.customReferencialName;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};