
import { useService } from "@/setup/connectClient";
import { ProjectController } from "@socotec.io/socio-grpc-api/connect/services/rapsosps_back/projects_pb";
import Operation from "@/models/Operation.js";
import { Agency, Batch } from "@socotec.io/socio-vue-components";
import { MISSIONS } from "@/utils/consts/missions";

const client = useService(ProjectController);

const state = {
  currentOperation: null,
  bimdataProjectToken: null,
};

const getters = {
  getOperation: (state) => {
    return Operation.query()
      .where("uuid", state.currentOperation?.uuid)
      .with("manager")
      .with("case")
      .first();
  },
  getAgency: (state) => {
    return Agency.query()
      .where("code", state.currentOperation?.agencyCode)
      .with("agencyGroup")
      .first();
  },
  getOperationUuid: (state) => {
    return state.currentOperation ? state.currentOperation.uuid : null;
  },
  getOperationOriginId: (state) => {
    return state.currentOperation.originId;
  },
  getProjectAccessToken: (state) => {
    return state.bimdataProjectToken ? state.bimdataProjectToken.token : null;
  },
  isCurrentOperationKDAABC: (state) => {
    return [MISSIONS.KDAA, MISSIONS.KDAB, MISSIONS.KDAC].includes(
      state.currentOperation.mission
    );
  },
  isCurrentOperationKdad: (state) => {
    return state.currentOperation?.mission === MISSIONS.KDAD;
  },
  isOperationImportedFromV1: (state) => {
    return state.currentOperation.oldProjectId ? true : false;
  }
};

const actions = {
  async fetchOperations(_, metadata) {
    const response = await client.list({}, { headers: metadata });
    const projects = response.results;
    await Operation.insert({ data: projects });
  },

  async retrieveOperation({ commit }, projectId) {
    const response = await client.retrieve({ uuid: projectId });
    const operation = response;

    commit("SET_CURRENT_OPERATION", operation);
    await Operation.insert({ data: operation });

    return operation;
  },

  async updateOperation({ commit }, operation) {
    const response = await client.update(operation);
    const updatedOperation = response;

    await Operation.insert({ data: updatedOperation });

    commit(
      "SET_CONTACT_CLIENT_USERMANAGEMENT_UUID",
      updatedOperation.contactClientUsermanagementUuid
    );

    return updatedOperation;
  },

  async fetchBimDataAccessToken({ commit, rootGetters }) {
    try {
      const response = await client.projectBimdataTokenRetrieve({
        uuid: rootGetters["operation/getOperationUuid"],
      });
      commit(
        "SET_BIMDATA_PROJECT_TOKEN",
        response.bimdataProjectToken
      );
      return response.bimdataProjectToken;
    } catch (err) {
      throw new Error(err);
    }
  },

  async searchOperation(context, { searchText, filters }) {
    const metadata = {
      filters: JSON.stringify({ search: searchText, ...filters }),
    };
    const response = await client.list({}, { headers: metadata });
    const results = response.results;
    return await Promise.all(
      results.map(async (item) => {
        return await new Operation(item);
      })
    );
  },

  async previewDataToCopy(context, settings) {
    const response = await client.previewDataToCopy(settings);
    return response;
  },

  async copyDataBetweenOperations(context, settings) {
    await client.copyDataBetweenProjects(settings);
    // INFO - MS - 21/02/2022 - Batches must be overrided by the duplication
    // deleteAll here is to avoid temporary duplication until page reload because we insert batches
    Batch.deleteAll();
  },

  async archiveOrUnarchiveOperation(context, { chronorapso, isArchived }) {
    await client.archiveOrUnarchiveProject({
      chronorapso,
      isArchived,
    });

    await Operation.update({
      where: chronorapso,
      data: { isArchived: isArchived },
    });
  },

  async resetOperationReferencial({ rootGetters }, referencialName) {
    await client.resetReferencialData({
      uuid: rootGetters["operation/getOperationUuid"],
      referencialName,
    });
  },

  async updateClientManagementOperation({ commit, state }, {
    usermanagementUuid,
    substituteManagerUsermanagementUuid
  }) {
    await commit("SET_CLIENT_MANAGEMENT_IDS", {
      usermanagementUuid,
      substituteManagerUsermanagementUuid
    });
    await Operation.insert({
      data: state.currentOperation,
    });
  },
};

const mutations = {
  SET_CURRENT_OPERATION: (state, operation) => {
    state.currentOperation = operation;
  },
  SET_CONTACT_CLIENT_USERMANAGEMENT_UUID: (state, usermanagementUuid) => {
    state.currentOperation.contactClientUsermanagementUuid = usermanagementUuid;
  },
  SET_CLIENT_MANAGEMENT_IDS: (state, {
    usermanagementUuid,
    substituteManagerUsermanagementUuid
  }) => {
    state.currentOperation.managerUsermanagementUuid = usermanagementUuid;
    state.currentOperation.substituteManagerUsermanagementUuid = substituteManagerUsermanagementUuid;
  },
  SET_BIMDATA_PROJECT_TOKEN: (state, token) => {
    state.bimdataProjectToken = token;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};