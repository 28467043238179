
import { Alert } from "@socotec.io/socio-vue-components";
import { useService } from "@/setup/connectClient";
import { AlertController } from "@socotec.io/socio-grpc-api/connect/services/rapsosps_back/alert_pb";

const client = useService(AlertController);

const state = {};

const getters = {
  getAlerts:
    (state, getter, rootState, rootGetters) =>
    (displayTerminated = false) => {
      let query = Alert.query()
        .where("project", rootGetters["operation/getOperationUuid"])
        .orderBy("endDate");
      if (!displayTerminated) {
        query = query.where("isLifted", false);
      }
      return query.get();
    },
};

const actions = {
  async fetchAlerts(context, metadata) {
    const response = await client.list({}, { headers: metadata });
    await Alert.insert({
      data: response.results,
    });
  },

  async createAlert(context, alert) {
    const response = await client.create(alert);
    await Alert.insert({ data: response });
  },

  async updateAlert(context, alert) {
    const response = await client.update(alert);
    await Alert.update({
      where: alert.uuid,
      data: response,
    });
    return alert.uuid;
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};