
import { DocumentType, DocumentStatus } from "@socotec.io/socio-vue-components";
import { Document } from "@/models/Document";
import { restDocumentClient } from "@/setup/restDocumentClient";
import { utils } from "@socotec.io/socio-vue-components";
const documentConst = utils.documentsConstants;
import { documentStatuses, documentTypes } from "@/utils/consts/documents";
import { useService } from "@/setup/connectClient";
import { TagController, DocumentTypeController, DocumentStatusController, DocumentCustomController } from "@socotec.io/socio-grpc-api/connect/services/document/document_pb";
import { listAll } from "@socotec.io/socio-grpc-api/connect/utils";
import { omit } from "lodash";
import {
  snakeToCamel,
} from "@/utils/utilsGrcpRest";

const tagClient = useService(TagController);
const documentTypeClient = useService(DocumentTypeController);
const documentStatusClient = useService(DocumentStatusController);
const documentCustomClient = useService(DocumentCustomController);

const state = {
  documentSelected: [],
  documentTags: [], // TODO - MS - Vuex orm model for tags
  documentsCount: 0,
  documentTablePageSize: 10,
  backendOrdering: true,
};

const getters = {
  getDocuments: (state) => {
    const documentsQuery = Document.query();
    if (state.backendOrdering) {
      return documentsQuery.limit(state.documentTablePageSize).get();
    } else {
      return documentsQuery
        .orderBy("createdAt", "desc")
        .limit(state.documentTablePageSize)
        .get();
    }
  },
  getAllDocuments: () => {
    return Document.query()
      .orderBy("createdAt", "desc")
      .get();
  },
  getDocumentsByType: () => (type) => {
    return Document.query()
      .orderBy("createdAt", "desc")
      .where("docType", type)
      .get();
  },
  getDocumentSelected: (state) => {
    return state.documentSelected;
  },
  getDocumentTags: (state) => {
    return state.documentTags;
  },
  getDocumentCount: (state) => {
    return state.documentsCount;
  },
  getDocumentTablePageSize: (state) => {
    return state.documentTablePageSize;
  },
  getDocumentTypes() {
    return DocumentType.query().orderBy("name").get();
  },
  getDocumentStatus() {
    return DocumentStatus.query().orderBy("name").get();
  },
  getIssuedDocumentStatusUuid() {
    return DocumentStatus.query().where("name", documentStatuses.ISSUED).first()
      .uuid;
  },
  getDocumentTypeByUuid: () => (uuid) => {
    return DocumentType.query().where("uuid", uuid).first();
  },
  getDocumentTypeByName: () => (name) => {
    return DocumentType.query().where("name", name).first();
  },
  getAvailableManualUploadDocumentTypes() {
    const availableDocumentTypes = [
      documentTypes.CONVENTION,
      documentTypes.DOCUMENT_RECEIVED,
      documentTypes.PHOTO,
      documentTypes.PPSPS,
      documentTypes.DOCUMENT_SENT,
    ];

    return DocumentType.query()
      .where(({ name }) => {
        return availableDocumentTypes.includes(name);
      })
      .get();
  },
};

const actions = {
  setSelectedDocument({ commit }, documents) {
    commit("SET_SELECTED_DOCUMENTS", documents);
  },
  async fetchDocumentTags({ commit }, params) {
    try {
      const response = await tagClient.list({}, { headers: params.metadata });
      commit("SET_TAGS", response.results);
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },

  async fetchDocumentTypes() {
    const response = await listAll(documentTypeClient.list, {}, {});
    await DocumentType.insert({ data: response });
  },

  async fetchDocumentStatus() {
    const response = await listAll(documentStatusClient.list, {}, {});
    await DocumentStatus.insert({ data: response });
  },

  /**
   * Fetch document by id
   * @param [_]
   * @param uuid
   * @returns {Promise<Document>}
   */
  async fetchDocument(_, uuid) {
    const data = await documentCustomClient.documentCustomExtendRetrieve({ uuid });
    await Document.insert({ data });
    return data;
  },

  async fetchDocumentList({ commit }, { metadata, createData = true }) {
    const response = await documentCustomClient.documentCustomExtendList({}, { headers: metadata });

    if (!createData) return response.results;

    commit("UPDATE_DOCUMENT_COUNT", response.count);

    await Document.create({ data: response.results });
  },

  async insertImportDocument({ commit, state }, documentUuidList) {
    const metadata = {
      filters: JSON.stringify({
        uuid: documentUuidList.join(',')
      }),
      pagination: JSON.stringify({
        page_size: 100, // hopefully nobody will import more than 100 docs at once
        page: 1,
      }),
    };

    const response = await documentCustomClient.documentCustomExtendList({}, { headers: metadata });

    commit("UPDATE_DOCUMENT_COUNT", state.documentsCount + response.count);
    await Document.insert({ data: response.results });
  },

  async createDocument(
    { commit, getters, dispatch },
    { operationId, ...datas }
  ) {
    let result = null;
    if (
      datas.form.origin === documentConst.DocumentSource.BIMDATA_GED &&
      datas.file !== null
    ) {
      result = await dispatch("createBimdataDocument", {
        operationId,
        ...datas,
      });
    } else {
      result = await dispatch("createManualDocument", {
        operationId,
        ...datas,
      });
    }
    commit("UPDATE_DOCUMENT_COUNT", getters.getDocumentCount + 1);
    commit("UPDATE_DOCUMENT_ORDER_BY_BACKEND", false);
    return result;
  },

  /**
   * Fetch last document dates pey company
   * @param [_]
   * @param documentType {string}
   * @param objectIds {string[]}
   * @param companiesId {string[]}
   * @returns {Promise<[{recentDate, oldestDate, companyUuid}]>}
   */
  async fetchLastDocumentDatePerContributor(
    _,
    { documentType, objectIds, companiesId }
  ) {

    const response = await documentCustomClient.getDocumentDatesPerCompany({
      docType: documentType,
      objectIds: objectIds,
      companyUuid: companiesId,
    });
    return response.results;
  },

  async createBimdataDocument({ rootGetters }, { operationId, ...datas }) {
    const docFile = new FormData();
    docFile.append("file", datas.file);
    docFile.append("cloudId", process.env.VUE_APP_CLOUD_ID);
    docFile.append(
      "gedProjectId",
      rootGetters["operation/getOperationOriginId"]
    );
    datas.form.relatedObjects = [operationId];
    datas.form.servicesRelatedName = [
      process.env.VUE_APP_RAPSOSPS_SERVICE_ID,
    ];
    datas.form.metaDatas = JSON.stringify(datas.form.metaDatas);
    Object.entries(datas.form).forEach(([key, value]) => {
      docFile.append(key, value);
    });
    try {
      const bimDataDoc = await restDocumentClient.post(
        "/documents/upload_document/",
        docFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      let data = snakeToCamel({
        ...bimDataDoc.data,
        ...bimDataDoc.data.document,
      });
      return await Document.insert({ data });
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },
  /**
   * Create a manual document
   * @param context
   * @param operationId
   * @param datas
   * @returns {Promise<{documents: Document[]}>}
   */
  async createManualDocument(context, { operationId, ...datas }) {
    // INFO - MS - 22/07/2021 - GRPC call on microservice Document
    datas.form.relatedObjects = [operationId];
    datas.form.servicesRelatedName = [
      process.env.VUE_APP_RAPSOSPS_SERVICE_ID,
    ];
    const docData = datas.form;
    try {
      const response = await documentCustomClient.createWithDocument(docData);
      return await Document.insert({ data: response });
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },

  async updateDocument({ dispatch }, data) {
    if (
      data.form.origin === documentConst.DocumentSource.BIMDATA_GED &&
      data.file !== null &&
      !data.multiple
    ) {
      return await dispatch("updateDocumentOnBimdata", data);
    }
    const documentForm = omit(data.form,       
      "isFromLegacySoftware",
      "oldOriginId",
      "originId",
      "gedProjectId",
      "mimeType",
      "file",
      "createdAt",
      "updatedAt"
    )

    try {
      const response = await documentCustomClient.partialUpdateDocumentCustomExtend(documentForm);
      await Document.update(response);
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },
  async updateDocumentOnBimdata({ rootGetters }, data) {
    // techDebt - Léo Hauchecorne - 09/02/2023 - Should use the gRPC api of document, but currently, several things
    // are done differently with the REST one compared to the gRPC one.
    // Moreover, the DocumentUploader from socio-grpc-api js utils expects a document and a documentCustom that are not
    // truely equivalent to Document and DocumentCustom models from the microservice, and it is ambiguous which fields of each are actually used.
    // Passing the same vuex-orm model Documen to both these parameters could do the trick. But to avoid unexpected regression,
    // I chose to stick to the REST api, and hopefully some day, the document micro-service and its gRPC api will be refactored.
    const docFile = new FormData();
    docFile.append("file", data.file);
    docFile.append("cloudId", process.env.VUE_APP_CLOUD_ID);
    data.form.servicesRelatedName = [
      process.env.VUE_APP_RAPSOSPS_SERVICE_ID,
    ];
    data.form.relatedObjects = [rootGetters["operation/getOperationUuid"]];
    Object.entries(data.form.$toJson()).forEach(([key, value]) => {
      // INFO - Léo Hauchecorne - 09/02/2023 - on the back, metaDatas is expected to be a JSON string.
      // However, the other values are expected to be as is.
      if (key === "metaDatas") {
        docFile.append(key, JSON.stringify(value));
      } else {
        docFile.append(key, value);
      }
    });
    // INFO - MS - 22/07/2021 - ensure that bimdata project id is set
    //when user update a MANUAL document to a BIMDATA_GED document
    docFile.set("gedProjectId", rootGetters["operation/getOperationOriginId"]);
    try {
      const bimDataDoc = await restDocumentClient.put(
        "/documents/upload_document/",
        docFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      let data = snakeToCamel({
        ...bimDataDoc.data,
        ...bimDataDoc.data.document,
      });
      return await Document.update({ data });
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },
  async updateMultipleDocs(context, { data, documentIds }) {
    const documents = documentIds.map((uuid) => ({
      uuid,
      ...data,
    }));

    const response = await documentCustomClient.bulkUpdateDocuments({
      documents
    });


    await Document.update({ data: response.results });
  },
  /**
   * Delete a document
   * @param [context]
   * @param documentUuid Document uuid
   * @returns {object} { code, message}
   */
  async removeDocument({ commit, getters }, documentUuid) {
    const response = await documentCustomClient.destroy({ uuid: documentUuid });

    if (response.code === "SUCCESS") {
      commit("UPDATE_DOCUMENT_COUNT", getters.getDocumentCount - 1);
      return await Document.delete(documentUuid);
    }

    throw new Error(response.message);
  },
  async getDocumentUrlAndName(context, docCustomUuid) {
    const params = {
      docCustomUuid,
    };
    try {
      const file = await restDocumentClient.get(
        "/documents/get_document_url_and_name/",
        { params }
      );
      return { fileUrl: file.data.file_url, fileName: file.data.file_name, size: file.data.size };
    } catch (error) {
      throw new Error(error);
    }
  },
  async updateDocumentStatus(_, { documentId, status }) {


    const result = documentCustomClient.partialUpdate({
      uuid: documentId,
      docStatus: status,
      PartialUpdateFields: ["docStatus"],
    });

    await Document.update(result);
  },
};

const mutations = {
  SET_SELECTED_DOCUMENTS: (state, documents) => {
    state.documentSelected = documents;
  },
  SET_TAGS: (state, tags) => {
    state.documentTags = tags.map((tag) => tag.name);
  },
  RESET_TAGS: (state) => {
    state.documentTags = [];
  },
  UPDATE_DOCUMENT_COUNT: (state, newTotal) => {
    state.documentsCount = newTotal;
  },
  UPDATE_DOCUMENT_TABLE_PAGE_SIZE: (state, pageSize) => {
    state.documentTablePageSize = pageSize;
  },
  UPDATE_DOCUMENT_ORDER_BY_BACKEND: (state, backendOrdering) => {
    state.backendOrdering = backendOrdering;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};