// INFO - M.S - 20/05/2021 - flatten the responseObject
// example :
//
// {             {
//   a:2,         a:2,
//   b: {   ==>   c:3
//     c:3       }
//   }
// }
const flattenObject = (obj) => {
  const flattened = {};
  Object.keys(obj).forEach((key) => {
    if (!Array.isArray(obj[key]) && obj[key] instanceof Object) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });
  return flattened;
};

export const snakeToCamel = (data, flatten) => {
  if (!data) {
    return;
  }
  if (flatten === "flatten") {
    data = flattenObject(data);
  }

  Object.keys(data).forEach((key) => {
    if (key.includes("_")) {
      let newKey = key.replace(/([_][a-z])/g, (match) =>
        match.toUpperCase().replace("_", "")
      );
      Object.assign(data, { [newKey]: data[key] });
      delete data[key];
    }
  });
  return data;
};

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z0-9]/g, (ltr, idx) =>
    idx > 0 ? `_${ltr.toLowerCase()}` : ltr.toLowerCase()
  );

export const removeUndefinedFields = (object) => {
  Object.keys(object).forEach((key) =>
    object[key] === undefined ? delete object[key] : {}
  );
  return object;
};
