
import { useService } from "@/setup/connectClient";
import { MissionController } from "@socotec.io/socio-grpc-api/connect/services/rapsosps_back/projects_pb";

const client = useService(MissionController);

const state = {};

const getters = {};

const actions = {
  async fetchMissions(context, { metadata }) {
    try {
      const response = await client.list({}, { headers: metadata });
      return response.results;
    } catch (error) {
      console.error("Error fetching missions:", error);
      throw error;
    }
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};