
import { Client } from "@socotec.io/socio-vue-components";
import { useService } from "@/setup/connectClient";
import { ClientController as RapsospsClientController } from "@socotec.io/socio-grpc-api/connect/services/rapsosps_back/projects_pb";
import { ClientController as AtlasClientController } from "@socotec.io/socio-grpc-api/connect/services/atlas_as_a_service/atlas_pb";

const rapsospsClient = useService(RapsospsClientController);
const atlasClient = useService(AtlasClientController);

const state = {};

const getters = {};

const actions = {
  /**
   * Retrieve a client
   * @param [_]
   * @param clientId Client uuid
   * @returns {Promise<Client>}
   */
  async fetchClient(_, clientId) {
    try {
      const response = await rapsospsClient.retrieve({ uuid: clientId });
      
      await Client.insert({
        data: response,
      });

      return response;
    } catch (error) {
      console.error("Error fetching client:", error);
      throw error;
    }
  },

  /**
   * Retrieve a atlas client
   * @param [_]
   * @param clientId Client uuid
   * @returns {Promise<Client>}
   */
  async fetchAtlasClient(_, clientId) {
    try {
      const response = await atlasClient.retrieve({ uuid: clientId });
      return response;
    } catch (error) {
      console.error("Error fetching atlas client:", error);
      throw error;
    }
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};