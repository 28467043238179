
import {
  ReportTemplate,
  ReportType,
  utils,
} from "@socotec.io/socio-vue-components";
import { useService } from "@/setup/connectClient";
import { ReportController, ReportTemplateController, ReportTypeController } from "@socotec.io/socio-grpc-api/connect/services/report_generator/reports_pb";
import { MISSIONS } from "@/utils/consts/missions";

import { Report } from "@/models/Report";

const templateCodes = utils.report.templateCodes;
const reportStatuses = utils.report.statuses;

const reportClient = useService(ReportController);
const reportTemplateClient = useService(ReportTemplateController);
const reportTypeClient = useService(ReportTypeController);

const state = {
  reportsCount: 0,
  isAnalysisConceptionSent: false,
  visitDate: Date.now(),
};

const getters = {
  /**
   * Get reports within Vuex Store
   * @returns {Report[]}
   */
  getReports() {
    return Report.all();
  },
  getReportsByTemplateId: () => (template_id) => {
    return Report.query().where("template", template_id).all();
  },
  getReportsCount: (state) => {
    return state.reportsCount;
  },
  getTemplateByName: () => (name) => {
    return ReportTemplate.query().where("name", name).first();
  },
  getTemplateByCode: () => (code) => {
    return ReportTemplate.query().where("code", code).first();
  },
  getTemplateByUuid: () => (uuid) => {
    return ReportTemplate.query().where("uuid", uuid).first();
  },
  getTemplates() {
    return ReportTemplate.all();
  },
  sortedTemplates() {
    return ReportTemplate.all().sort(
      (a, b) => a.orderingPosition - b.orderingPosition
    );
  },
  getTypes() {
    return ReportType.all();
  },
  sortedTypes() {
    return ReportType.all().sort(
      (a, b) => a.orderingPosition - b.orderingPosition
    );
  },
  getVisitDate: (state) => {
    return state.visitDate;
  },
  getReportTypesForGivenMission: () => (mission) => {
    if (mission === MISSIONS.KDAD) {
      return ReportType.query().where(reportType => reportType.name === 'Réalisation' || reportType.name === 'Conception').get();
    }
    return getters.sortedTypes();
  }
};

const actions = {
  /**
   * Fetch report list
   * @param [commit]
   * @param metadata
   * @param projectId
   * @returns {Promise<*>}
   */
  async fetchReportList({ commit }, { metadata }) {
    const response = await reportClient.list({}, { headers:
        {
          pagination: JSON.stringify(
            {
              ...metadata.pagination
            }
          ),
          filters: JSON.stringify({
            ...metadata.filters, service_id: process.env.VUE_APP_RAPSOSPS_SERVICE_ID
          })
        }
    });

    commit("UPDATE_REPORTS_COUNT", response.count);

    const reportsList = response.results;
    return await Report.insert({
      data: reportsList,
    });
  },

  /**
   * Fetch report
   * @param [commit]
   * @param reportUuid Report uuid
   * @returns {Promise<any>}
   */
  async fetchReport({ commit }, { reportUuid }) {
    const response = await reportClient.retrieve({ uuid: reportUuid });
    const reportData = response;

    const reportInsert = await Report.insert({
      data: reportData,
    });

    await commit("UPDATE_REPORTS_COUNT", await Report.query().count());

    return reportInsert.reports[0];
  },

  /**
   * Fetch last reports per contributors mails
   * @param [_]
   * @param usermanagementUuids {string[]}
   * @param templateId {string}
   * @param objectIds {string[]}
   * @returns {Promise<*>}
   */
  async fetchLastReportDatePerContributor(
    _,
    { usermanagementUuids, templateId, objectIds }
  ) {
    const response = await reportClient.getLastReportDatePerContributor({
      recipientUsermanagementUuids: usermanagementUuids,
      template: templateId,
      objectIds: objectIds,
    });

    return response.reports;
  },

  async fetchLastCRDate(_, { projectId }) {
    const response = await reportClient.fetchLastSPSCR({ objectId: projectId });
    return response.results;
  },

  /**
   * Fetch version attr from latest report by template Id
   * @param [_]
   * @param templateId {string}
   * @param objectId {string}
   * @returns {Promise<*>}
   */
  async fetchLastReportVersionByTemplateId(_, { objectId, templateId }) {
    const response = await reportClient.reportLastVersion({
      objectId,
      templateId,
    });

    return response.version;
  },

  /**
   * Fetch report generation data from latest (sent) report by template Id
   * @param [_]
   * @param templateId {string}
   * @param objectId {string}
   * @returns {Promise<*>}
   */
  async fetchLastReportData(_, { objectId, templateId }) {
    const response = await reportClient.lastSentReportData({
      objectId,
      templateId,
    });

    const reportData = response;
    reportData.reportGenerationData = response.reportGenerationData;

    return reportData;
  },

  async retrieveLastReportByTemplate(_, { operationUuid, templateCode, metadata }) {
    const response = await reportClient.getLastFullReportForObjectId(
      {
        objectId: operationUuid,
        templateCode: templateCode,
      },
      { headers: metadata }
    );

    return response;
  },

  /**
   * Fetch report template list
   * @param [_]
   * @param metadata
   * @returns {Promise<reportTemplate[ReportTemplate[]]>}
   */
  async fetchReportTemplateList(_, metadata) {
    const response = await reportTemplateClient.list({}, { headers: metadata });

    return await ReportTemplate.insert({
      data: response.results,
    });
  },

  /**
   * Fetch report type list
   * @param [_]
   * @param metadata
   * @returns {Promise<*>}
   */
  async fetchReportTypeList(_, metadata) {
    const response = await reportTypeClient.list({}, { headers: metadata });

    return await ReportType.insert({
      data: response.results,
    });
  },

  async checkIfAnalysisConceptionIsDiffused({ commit, dispatch, rootGetters }) {
    // On V2 projects and only for KDAA, KDAB, KDAC missions,
    // check if Initial Conception Analysis is sent.
    // If not some functionalities could be disabled
    // (for example PGC or DIUO referencials creation)

    if (
      !rootGetters["operation/isCurrentOperationKDAABC"] ||
      rootGetters["operation/isOperationImportedFromV1"]
    ) {
      return;
    }

    const metadata = {
      pagination: JSON.stringify({
        page_size: 100,
        page: 1,
      }),
      filters: JSON.stringify({
        status: reportStatuses.sent,
        templateCode: templateCodes.INITIAL_CONCEPTION_ANALYSIS,
        objectIds: [rootGetters["operation/getOperationUuid"]],
      }),
    };

    try {
      const { reports } = await dispatch("fetchReportList", { metadata });
      if (reports && reports.length > 0) {
        commit("SET_IS_INITIAL_CONCEPTION_ANALYSIS_SENT");
      }
    } catch (e) {
      console.log(e);
    }
  },

  /**
   * Delete a report
   * @param [context]
   * @param report
   * @returns {Promise<void>}
   */
  async deleteReport(context, report) {
    await reportClient.destroy({ uuid: report.uuid });
    await Report.delete(report.uuid);
  },

  async generateDraftReport(context, reportData) {
    try {
      const stream = reportClient.createDraft(reportData);
      return stream;
    } catch (err) {
      console.error(err);
    }
  },

  setVisitDate({ commit }, date) {
    commit("SET_VISIT_DATE", date);
  },
  /**
   * Fetch version attr from latest report by template Id
   * @param [_]
   * @param templateId {string}
   * @param objectId {string}
   * @returns {Promise<*>}
   */
  async getLastFullReportForObjectId(_, { operationUuid, templateCode }) {
    const response = await reportClient.getLastFullReportForObjectId({
      objectId: operationUuid,
      templateCode: templateCode,
    });

    const reportData = response;
    reportData.reportGenerationData = response.reportGenerationData;

    return reportData;
  },

  /**
   * Send preview report
   * @param [context]
   * @param report
   * @param attachements
   * @returns {Promise<void>}
   */
  async sendPreviewReport(_, { uuid, attachments, template, body, subject }) {
    const response = await reportClient.sendPreviewMail({
      uuid,
      template,
      attachments,
      body,
      subject,
    });
    return response;
  },

  async sendReportMail(_, form) {
    const response = await reportClient.sendMailToRecipients({
      ...form,
      attachments: form.attachments.map((file) => file.id),
    });
    return response;
  },

  async setReportStatus(_, { report, status }) {
    await Report.update({
      where: report.uuid,
      data: { status },
    });
  }
};

const mutations = {
  UPDATE_REPORTS_COUNT: (state, newTotal) => {
    state.reportsCount = newTotal;
  },
  SET_IS_INITIAL_CONCEPTION_ANALYSIS_SENT: (state) => {
    state.isAnalysisConceptionSent = true;
  },
  SET_VISIT_DATE: (state, date) => {
    state.visitDate = date;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};