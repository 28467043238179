
import Vue from "vue";
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";
import createPersistedState from "vuex-persistedstate";

import { setTelemetryUser } from "@socotec.io/socio-vue-components";

import {
  Alert,
  Project,
  User,
  Agency,
  AgencyGroup,
  Case,
  Client,
  ReportTemplate,
  ReportType,
  StandByReport,
  Contributor,
  ContributorBatch,
  Role,
  Company,
  Batch,
  DefaultBatch,
  RapsoSPSNode,
  ReferencialNode,
  RapsoSPSTechnicalDoc,
  RapsoSPSNodeTechnicalDoc,
  DocumentType,
  DocumentStatus,
  CompanyProjectRole,
  Observation as BaseObservation
} from "@socotec.io/socio-vue-components";

import Pgc from "@/models/PGC";
import Diuo from "@/models/DIUO";
import DiuoExtra from "@/models/DIUOExtra";
import InitialConception from "@/models/InitialConception";
import Operation from "@/models/Operation";
import RapsoSPSCase from "@/models/RapsoSPSCase";
import OverridenLastCr from "@/models/OverridenLastCr";
import ExtendedRole from "@/models/ExtendedRole";
import { Observation } from "@/models/ExtendedObservation";
import { Document } from "@/models/Document";
import { Report } from "@/models/Report";

// Vuex OIDC
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import { oidcSettings } from "@/setup/OIDCSettings";

// Store module
import { notifications as NotificationModule } from "@socotec.io/socio-vue-components";
import UserModule from "./modules/user";
import ReportModule from "./modules/report";
import DocumentModule from "./modules/document";
import StandByReportModule from "./modules/standbyreport";
import OperationModule from "./modules/operation";
import ContributorModule from "./modules/contributor";
import RoleModule from "./modules/role";
import BatchModule from "./modules/batch";
import RefencialModule from "./modules/referencial";
import CompanyModule from "./modules/company";
import ClientModule from "./modules/client";
import CaseModule from "./modules/case";
import TechnicalDoc from "./modules/technicalDoc";
import NetworkModule from "./modules/network";
import OfflineReportModule from "./modules/offlineReport";
import Common from "./modules/common";
import Mission from "./modules/mission";
import AlertModule from "./modules/alert";
import OverridenLastCrModule from "./modules/overridenLastCr";
import notificationManagementModule from "./modules/notificationManagement";

// Auth hooks
import { tryRefreshSessionOrRedirect } from "@/setup/auth-hooks";

// Client
import { initDocumentAuth } from "@/setup/restDocumentClient";
import { initReportAuth } from "@/setup/restReportClient";
import { initRapsoSpsAuth } from "@/setup/socioGrpcClient";
import { stonlyIdentifyWidget } from "@/api/stonlyIdentifyWidget";

Vue.use(Vuex);

const database = new VuexORM.Database();

database.register(Alert);
database.register(Case);
database.register(RapsoSPSCase);
database.register(Project);
database.register(Operation);
database.register(User);
database.register(Agency);
database.register(AgencyGroup);
database.register(Client);
database.register(StandByReport);
database.register(BaseObservation);
database.register(Observation);
database.register(Document);
database.register(Report);
database.register(ReportTemplate);
database.register(ReportType);
database.register(Contributor);
database.register(ContributorBatch);
database.register(Role);
database.register(Company);
database.register(Batch);
database.register(DefaultBatch);
database.register(ReferencialNode);
database.register(RapsoSPSNode);
database.register(Pgc);
database.register(Diuo);
database.register(DiuoExtra);
database.register(InitialConception);
database.register(RapsoSPSTechnicalDoc);
database.register(RapsoSPSNodeTechnicalDoc);
database.register(OverridenLastCr);
database.register(DocumentType);
database.register(DocumentStatus);
database.register(ExtendedRole);
database.register(CompanyProjectRole);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    oidc: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        namespaced: true,
        dispatchEventsOnWindow: true,
      },
      {
        userLoaded: (user) => {
          initRapsoSpsAuth(user.access_token);
          initDocumentAuth(user.access_token);
          initReportAuth(user.access_token);
          setTelemetryUser(user); // HERE
          stonlyIdentifyWidget(user.profile.email, user.profile.name);
        },
        userUnloaded: () => console.log("OIDC user is unloaded"),
        accessTokenExpiring: () => console.log("Access token will expire"),
        accessTokenExpired: async () => {
          console.log("Access token did expire");
          await tryRefreshSessionOrRedirect();
        },
        silentRenewError: () => console.log("OIDC user is unloaded"),
        automaticSilentRenewError: (payload) =>
          console.log("Automatic silent renew failed.", payload.error),
        userSignedOut: () => console.log("OIDC user is signed out"),
        oidcError: (payload) =>
          console.log(`An error occured at ${payload.context}:`, payload.error),
      }
    ),
    notifications: NotificationModule,
    user: UserModule,
    report: ReportModule,
    document: DocumentModule,
    standByReport: StandByReportModule,
    operation: OperationModule,
    contributors: ContributorModule,
    roles: RoleModule,
    batches: BatchModule,
    referencial: RefencialModule,
    companies: CompanyModule,
    clients: ClientModule,
    cases: CaseModule,
    technicalDoc: TechnicalDoc,
    network: NetworkModule,
    offlineReports: OfflineReportModule,
    common: Common,
    mission: Mission,
    alert: AlertModule,
    overridenLastCr: OverridenLastCrModule,
    notificationManagement: notificationManagementModule,
  },

  plugins: [
    VuexORM.install(database),
    createPersistedState({
      paths: ["offlineReports"],
    }),
  ],
});
